import axios from "axios";
import * as firestoreService from "../database/firestore";

export default {
  namespaced: true,

  state: {
    userData: null,
    grpData: null,
    founderPaymentData: null,
    subscribedInfo: null,
    singlePaymentForUser: null
  },

  getters: {
    user: (state) => state.userData,
    group: (state) => state.groupData,
    founderPayment: (state) => state.founderPaymentData,
    subscribedInfo: (state) => state.subscribedInfo,
    singlePaymentForUser: (state) => state.singlePaymentForUser,
  },

  mutations: {
    setUserData(state, user) {
      state.userData = user;
      if (user) {
        localStorage.setItem("getTab", user.pro_status);
      }
    },
    setGrpData(state, group) {
      state.groupData = group;
    },
    setFounderPayment(state, Payment) {
      state.founderPaymentData = Payment;
    },
    setsubscribedInfo(state, info) {
      state.subscribedInfo = info;
    },
    setSinglePayment(state, info) {
      state.singlePaymentForUser = info;
    },
  },

  actions: {
    getUserData({ commit, dispatch }) {
      axios
        .get(process.env.VUE_APP_API_URL + "user", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("authToken"),
          },
        })
        .then((response) => {
          commit("setUserData", response.data.userData);
          commit("setsubscribedInfo", response.data.packageType);
          localStorage.setItem("userId", response.data.userData.id);
          localStorage.setItem("userName", response.data.userData.name);
          localStorage.setItem("user_link", response.data.userData.user_link);
          localStorage.setItem("paymentToGet", response.data.paymentToGet.status);
          
            dispatch("getFounderPayment");
          
        })
        .catch((error) => {
          if (error.response.status === 401) {
            localStorage.removeItem("authToken");
            localStorage.removeItem("userId");
            localStorage.removeItem("userName");
            localStorage.removeItem("user_link");
            localStorage.removeItem("pro_status");
            localStorage.removeItem("pro_expiry");
            localStorage.removeItem("linkUrl");
            localStorage.removeItem("linkUrlActive");
            window.location.reload();
          } else if (error.response.status === 404) {
            localStorage.removeItem("authToken");
            localStorage.removeItem("userId");
            localStorage.removeItem("userName");
            localStorage.removeItem("user_link");
            localStorage.removeItem("pro_status");
            localStorage.removeItem("pro_expiry");
            localStorage.removeItem("linkUrl");
            localStorage.removeItem("linkUrlActive");
            window.location.reload();
          }
        });
    },
    getGrpData({ commit }, data) {
      commit("setGrpData", data);
    },
    getSinglePayment({ commit }, data) {
      commit("setSinglePayment", data);
    },
    getFounderPayment({ commit }) {
      commit("setErrors", {}, { root: true });
      return axios
        .get(process.env.VUE_APP_API_URL + `getFounderPaymentInfo`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("authToken"),
          },
        })
        .then((response) => {
          commit("setFounderPayment", response.data.data.data);
          localStorage.setItem("paymentToGet", response.data.data.status);
          localStorage.removeItem("linkUrl");
          return response;
        });
    },
    sendLoginRequest({ commit, dispatch }, data) {
      commit("setErrors", {}, { root: true });
      return axios
        .post(process.env.VUE_APP_API_URL + "login", data)
        .then((response) => {
          if (response.data.statusCode == 200) {
            commit("setUserData", response.data.data.user);
            commit("setGrpData", response.data.data.userByCopy);
            commit("setFounderPayment", response.data.data.paymentToGet);
            commit("setsubscribedInfo", response.data.data.packageType);
            if (response.data.data.user.email_verification == 1) {
              localStorage.setItem(
                "emailToVerify",
                response.data.data.user.email
              );
            }
            if(response.data.data.user.mobile_verification == 1){
              localStorage.setItem(
                "mobileToVerify",
                response.data.data.user.mob
              );
              localStorage.setItem(
                "mobileCodeToVerify",
                response.data.data.user.mob_code
              );
            } else if (!response.data.data.user.mob) { 
              localStorage.setItem(
                "mobileToVerify",
                "null" 
              );
            }
            localStorage.setItem(
              "invite_count",
              response.data.data.invite_count
            );
            localStorage.removeItem("linkUrl");
            localStorage.setItem("paymentToGet", response.data.data.paymentToGet?.status);
            localStorage.setItem("authToken", response.data.data.token);
            localStorage.setItem("userId", response.data.data.user.id);
            localStorage.setItem("userName", response.data.data.user.name);
            localStorage.setItem(
              "user_link",
              response.data.data.user.user_link
            );
            dispatch("getUserData");
            dispatch("getFounderPayment");
          }
          return response;
        });
    },
    sendResetPasswordRequest({ commit }, data) {
      commit("setErrors", {}, { root: true });
      return axios
        .get(process.env.VUE_APP_API_URL + `requestReset?email=${data.email}`)
        .then((response) => {
          localStorage.setItem("verifyEmail", data.email);
          return response;
        });
    },
    codeVerify({ commit }, data) {
      commit("setErrors", {}, { root: true });
      return axios
        .get(
          process.env.VUE_APP_API_URL +
            `codeVerfy?code=${data.code}&email=${data.email}`
        )
        .then((response) => {
          localStorage.setItem("verifiedCode", data.code);
          return response;
        });
    },
    changePassword({ commit }, data) {
      commit("setErrors", {}, { root: true });
      return axios
        .post(
          process.env.VUE_APP_API_URL +
            "passwordReset", data
        )
        .then((response) => {
          localStorage.removeItem("verifyEmail");
          localStorage.removeItem("verifiedCode");
          return response;
        });
    },
    verifyEmail({ commit }, data) {
      commit("setErrors", {}, { root: true });
      return axios
        .get(
          process.env.VUE_APP_API_URL +
            `verifyEmail?code=${data.code}&email=${data.email}`
        )
        .then((response) => {
          return response;
        });
    },
    verifyMobile({ commit }, data) {
      commit("setErrors", {}, { root: true });
      return axios
        .get(
          process.env.VUE_APP_API_URL +
            `verifyMobile?code=${data.code}&mob=${data.mob}`
        )
        .then((response) => {
          return response;
        });
    },
    verifyEmailRegisterRequest({ commit }, data) {
      commit("setErrors", {}, { root: true });
      return axios
        .post(
          process.env.VUE_APP_API_URL +
            `verifyEmailRegisterRequest?email=${data.email}`
        )
        .then((response) => {
          return response;
        });
    },
    verifyMobileRegisterRequest({ commit }, data) {
      commit("setErrors", {}, { root: true });
      return axios
        .post(process.env.VUE_APP_API_URL + "user/sendVerificationCode", data)
        .then((response) => {
          return response;
        });
    },
    async sendRegisterRequest({ commit, dispatch }, data) {
      commit("setErrors", {}, { root: true });
      return axios
        .post(process.env.VUE_APP_API_URL + "register", data)
        .then((response) => {
          if (response.data.statusCode == 200) {
            commit("setUserData", response.data.data.user);
            commit("setGrpData", response.data.data.userByCopy);
            commit("setFounderPayment", response.data.data.paymentToGet);
            localStorage.setItem("authToken", response.data.data.token);
            localStorage.setItem("userId", response.data.data.user.id);
            localStorage.setItem("userName", response.data.data.user.name);
            if (response.data.data.user.email_verification == 1) {
              localStorage.setItem(
                "emailToVerify",
                response.data.data.user.email
              );
            }else if(response.data.data.user.mobile_verification == 1){
              localStorage.setItem(
                "mobileToVerify",
                response.data.data.user.mob
              );
              localStorage.setItem(
                "mobileCodeToVerify",
                response.data.data.user.mob_code
              );
            }
            localStorage.setItem(
              "emailToVerify",
              response.data.data.user.email
            );
            localStorage.setItem(
              "mobileToVerify",
              response.data.data.user.mob
            );
            localStorage.setItem(
              "mobileCodeToVerify",
              response.data.data.user.mob_code
            );
            localStorage.setItem(
              "user_link",
              response.data.data.user.user_link
            );
            localStorage.setItem(
              "invite_count",
              response.data.data.invite_count
            );
            dispatch("userCreateFirebase");
          }
          return response;
        });
    },

    async userCreateFirebase() {
      try {
        var userId = localStorage.getItem("userId");
        var userName = localStorage.getItem("userName");
    
        // Create a user in Firestore
        const { id } = await firestoreService.addUser({
          username: userName,
          c_id: userId.toString(),
        });
    
        // Update Firestore user with _id
        await firestoreService.updateUser(id, { _id: id });
    
        // Send Firebase ID to the backend API
        await axios.post(process.env.VUE_APP_API_URL + "updateFirebaseId", {
          user_id: userId,
          firebase_id: id,
        });
    
        console.log("Firebase ID successfully updated in backend");
      } catch (error) {
        console.error("Error updating Firebase ID:", error);
      }
    },
    

    sendLogoutRequest({ commit }) {
      return axios
        .post(process.env.VUE_APP_API_URL + "logout")
        .then((response) => {
          commit("setUserData", null);
          localStorage.removeItem("authToken");
          localStorage.removeItem("userId");
          localStorage.removeItem("userName");
          localStorage.removeItem("user_link");
          localStorage.removeItem("verifyEmail");
          localStorage.removeItem("verifiedCode");
          localStorage.removeItem("openTabChat");
          localStorage.removeItem("pro_status");
          localStorage.removeItem("pro_expiry");
          localStorage.removeItem("getTab");
          localStorage.removeItem("linkUrl");
          localStorage.removeItem("notifyId");
          localStorage.removeItem("mobileToVerify");
          localStorage.removeItem("mobileCodeToVerify");
          localStorage.removeItem("invite_count");
          localStorage.removeItem("linkUrlActive");
          window.location.reload();
          return response;
        })
        .catch((error) => {
          if (error.response.status === 401) {
            localStorage.removeItem("authToken");
            localStorage.removeItem("userId");
            localStorage.removeItem("userName");
            localStorage.removeItem("user_link");
            localStorage.removeItem("pro_status");
            localStorage.removeItem("pro_expiry");
            localStorage.removeItem("linkUrl");
            localStorage.removeItem("linkUrlActive");
            window.location.reload();
          } else if (error.response.status === 404) {
            localStorage.removeItem("authToken");
            localStorage.removeItem("userId");
            localStorage.removeItem("userName");
            localStorage.removeItem("user_link");
            localStorage.removeItem("pro_status");
            localStorage.removeItem("pro_expiry");
            localStorage.removeItem("linkUrl");
            window.location.reload();
          }
          else if (error.response.status === 500) {
            localStorage.removeItem("authToken");
            localStorage.removeItem("userId");
            localStorage.removeItem("userName");
            localStorage.removeItem("user_link");
            localStorage.removeItem("pro_status");
            localStorage.removeItem("pro_expiry");
            localStorage.removeItem("linkUrl");
            window.location.reload();
          }
        });
    },
    sendVerifyResendRequest() {
      return axios.get(process.env.VUE_APP_API_URL + "email/resend");
    },
    sendVerifyRequest({ dispatch }, hash) {
      return axios
        .get(process.env.VUE_APP_API_URL + "email/verify/" + hash)
        .then(() => {
          dispatch("getUserData");
        });
    },
  },
};
