
import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const guest = (to, from, next) => {
  if (!localStorage.getItem("authToken")) {
    localStorage.removeItem("authToken");
    localStorage.removeItem("userId");
    localStorage.removeItem("userName");
    localStorage.removeItem("user_link");
    return next(next);
  } else {
    var linkUrl = window.location.pathname;
    var segment_array = linkUrl.split("/");
    var lastSegment = segment_array.pop();
    if (lastSegment !== "login" || lastSegment !== "register" || lastSegment !== "pending-invite") {
      localStorage.setItem("linkUrl", lastSegment);
      localStorage.setItem("linkUrlActive", lastSegment);
    }
    localStorage.getItem("linkUrl");
    return next("/");
  }
};
const auth = (to, from, next) => {
  if (localStorage.getItem("authToken")) {
    if (localStorage.getItem("emailToVerify")) {
      return next("/verify");
    }
    else if(localStorage.getItem("mobileToVerify")){
      return next("/verifyMobile");
    } 
    else {
      return next();
    }
  } else {
    localStorage.removeItem("authToken");
    localStorage.removeItem("userId");
    localStorage.removeItem("userName");
    localStorage.removeItem("user_link");
    return next("/login");
  }
};
const passwordRoute = (to, from, next) => {
  if (localStorage.getItem("verifyEmail")) {
    return next();
  } else {
    return next("/login");
  }
};
const emailVerify = (to, from, next) => {
  if (localStorage.getItem("emailToVerify")) {
    return next();
  }else {
    return next("/login");
  }
};
const mobileVerify = (to, from, next) => {
  if (localStorage.getItem("mobileToVerify") || localStorage.getItem("mobileToVerify") == "" || localStorage.getItem("mobileToVerify") == null) {
    return next();
  }else {
    return next("/login");
  }
};
const JoinGroupTeam = (to, from, next) => {
  if (localStorage.getItem("invite_count") != 0) {
    return next();
  }else {
    return next("/teams");
  }
};
// const founderUrl = (to, from, next) => {
//   if (localStorage.getItem("authToken")) {
//     if(localStorage.getItem("paymentToGet") ==1){
//       return next();
//     }else{
//       return next("/");
//     }
    
//   }else {
//     return next("/login");
//   }
// };
const routes = [
  {
    path: "/",
    name: "Home",
    beforeEnter: auth,
    component: () => import(/* webpackChunkName: "home" */ "../views/Home.vue"),
  },
  // {
  //   path: "/my-teams",
  //   name: "GameTime",
  //   beforeEnter: auth,
  //   component: () => import(/* webpackChunkName: "home" */ "../views/GameTime.vue"),
  // },
  {
    path: "/game-time",
    name: "NewGameTimePage",
    beforeEnter: auth,
    component: () => import(/* webpackChunkName: "home" */ "../views/NewGameTimePage.vue"),
  },
  {
    path: "/schedule/:teamId?",
    name: "GameSchedule",
    beforeEnter: auth,
    component: () => import(/* webpackChunkName: "home" */ "../views/GameTeamDetail.vue"),
  },
  {
    path: "/my-team/events/:teamId?",
    name: "GameEvents",
    beforeEnter: auth,
    component: () => import(/* webpackChunkName: "home" */ "../views/GameEvent.vue"),
  },
  {
    path: "/my-team/teammates/:teamId?",
    name: "GameTeammates",
    beforeEnter: auth,
    component: () => import(/* webpackChunkName: "home" */ "../views/GameTeammates.vue"),
  },
  {
    path: "/my-team/player-statistics/:teamId?",
    name: "PlayerStatistics",
    beforeEnter: auth,
    component: () => import(/* webpackChunkName: "home" */ "../views/GameStatistics.vue"),
  },
  {
    path: "/my-team/team-statistics/:teamId?",
    name: "TeamStatistics",
    beforeEnter: auth,
    component: () => import(/* webpackChunkName: "home" */ "../views/GameTeamStatistics.vue"),
  },
  {
    path: "*",
    name: "PageNotFound",
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/PageNotFound.vue"),
  },
  {
    path: "/post",
    name: "SinglePost",
    beforeEnter: auth,
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/SinglePost.vue"),
  },
  {
    path: "/tasks",
    name: "Tasks",
    beforeEnter: auth,
    component: () =>
      import(/* webpackChunkName: "Teams" */ "../views/Tasks.vue"),
  },
  {
    path: "/chat/team/:roomId?",
    name: "Teams",
    beforeEnter: auth,
    component: () =>
      import(/* webpackChunkName: "Teams" */ "../views/Teams.vue"),
    props: true, // Pass `roomId` as a prop
  },
  {
    path: "/chat/user/:roomId?",
    name: "Individuals",
    beforeEnter: auth,
    component: () =>
      import(/* webpackChunkName: "Individuals" */ "../views/Teams.vue"), // Use the same component if needed
    props: true,
  },
  {
    path: "/all-teams",
    name: "AllTeams",
    beforeEnter: auth,
    component: () =>
      import(/* webpackChunkName: "Teams" */ "../views/AllTeams.vue"),
  },
  {
    path: "/pros",
    name: "Pros",
    beforeEnter: auth,
    component: () =>
      import(/* webpackChunkName: "Teams" */ "../views/Pros.vue"),
  },
  {
    path: "/box-scores",
    name: "MyBoxScores",
    beforeEnter: auth,
    component: () =>
      import(/* webpackChunkName: "Teams" */ "../views/BoxScores.vue"),
  },
  {
    path: "/box-scores",
    name: "AllBoxScores",
    beforeEnter: auth,
    component: () =>
      import(/* webpackChunkName: "Teams" */ "../views/BoxScores.vue"),
  },
  {
    path: "/shop",
    name: "Shop",
    beforeEnter: auth,
    component: () =>
      import(/* webpackChunkName: "Teams" */ "../views/Shop.vue"),
  },
  // {
  //   path: "/events",
  //   name: "Events",
  //   beforeEnter: auth,
  //   component: () =>
  //     import(/* webpackChunkName: "Teams" */ "../views/Events.vue"),
  // },
  // {
  //   path: "/my-events/list",
  //   name: "MyEvents",
  //   beforeEnter: auth,
  //   component: () =>
  //     import(/* webpackChunkName: "Teams" */ "../views/MyEvents.vue"),
  // },
  {
    path: "/events/discover",
    name: "Events",
    beforeEnter: auth,
    component: () =>
      import(/* webpackChunkName: "Teams" */ "../components/NewGameTime/Events/DiscoverEvents.vue"),
  },
  {
    path: "/my-events",
    name: "MyEvents",
    beforeEnter: auth,
    component: () =>
      import(/* webpackChunkName: "Teams" */ "../components/NewGameTime/Events/MyEvents.vue"),
  },
  {
    path: "/my-event/details",
    name: "MyEventDetails",
    beforeEnter: auth,
    component: () =>
      import(/* webpackChunkName: "Teams" */ "../components/NewGameTime/Events/MyEventDetails.vue"),
  },
  {
    path: "/events/following",
    name: "FollowingEvents",
    beforeEnter: auth,
    component: () =>
      import(/* webpackChunkName: "Teams" */ "../components/NewGameTime/Events/FollowingEvents.vue"),
  },
// {
//     path: "/all-teams",
//     name: "AllTeams",
//     beforeEnter: auth,
//     component: () =>
//       import(/* webpackChunkName: "Teams" */ "../views/AllTeams.vue"),
//   },
  {
    path: "/teams/discover",
    name: "DiscoverTeams",
    beforeEnter: auth,
    component: () =>
      import(/* webpackChunkName: "Teams" */ "../components/NewGameTime/Teams/DiscoverTeams.vue"),
  },
  {
    path: "/ScriptFirebase-teams",
    name: "ScriptFirebase",
    beforeEnter: auth,
    component: () =>
      import(/* webpackChunkName: "Teams" */ "../components/Tasks/ScriptFirebase.vue"),
  },
  // {
  //   path: "/my-teams",
  //   name: "GameTime",
  //   beforeEnter: auth,
  //   component: () => import(/* webpackChunkName: "home" */ "../views/GameTime.vue"),
  // },
  {
    path: "/my-teams",
    name: "MyTeams",
    beforeEnter: auth,
    component: () =>
      import(/* webpackChunkName: "Teams" */ "../components/NewGameTime/Teams/MyTeams.vue"),
  },
  {
    path: "/teams/following",
    name: "FollowingTeams",
    beforeEnter: auth,
    component: () =>
      import(/* webpackChunkName: "Teams" */ "../components/NewGameTime/Teams/FollowingTeams.vue"),
  },
  {
    path: "/live",
    name: "AllLiveGames",
    beforeEnter: auth,
    component: () =>
      import(/* webpackChunkName: "Teams" */ "../views/AllLiveGames.vue"),
  },
  // {
  //   path: "/event/detail/:id",
  //   name: "EventDetails",
  //   beforeEnter: auth,
  //   component: () =>
  //     import(/* webpackChunkName: "Teams" */ "../views/EventDetails.vue"),
  // },
  {
    path: "/product-detail/:guid?",
    name: "ProductDetail",
    beforeEnter: auth,
    component: () =>
      import(/* webpackChunkName: "Teams" */ "../views/ProductDetail.vue"),
  },
  {
    path: "/cart-detail",
    name: "Cart",
    beforeEnter: auth,
    component: () =>
      import(/* webpackChunkName: "Teams" */ "../views/Cart.vue"),
  },
  {
    path: "/order",
    name: "Order",
    beforeEnter: auth,
    component: () =>
      import(/* webpackChunkName: "Teams" */ "../views/Order.vue"),
  },
  {
    path: "/order-history",
    name: "OrderHistory",
    beforeEnter: auth,
    component: () =>
      import(/* webpackChunkName: "Teams" */ "../views/OrderHistory.vue"),
  },
  {
    path: "/shipping",
    name: "Shipping",
    beforeEnter: auth,
    component: () =>
      import(/* webpackChunkName: "Teams" */ "../views/Shipping.vue"),
  },
  // {
  //   path: "/payments",
  //   name: "Payment",
  //   beforeEnter: auth,
  //   component: () =>
  //     import(/* webpackChunkName: "Teams" */ "../views/Payments.vue"),
  // },
  {
    path: "/payment",
    name: "Payment",
    beforeEnter: auth,
    component: () =>
      import(/* webpackChunkName: "Teams" */ "../views/Payments.vue"),
  },
  {
    path: '/association/events/:associationGuid',
    name: 'AssociationEvents',
    beforeEnter: auth,
    component: () => import(/* webpackChunkName: "AssociationEvents" */ '../views/AssociationEvents.vue')
  },
  {
    path: '/association/users/:associationGuid', // Include the associationGuid parameter
    name: 'AssociationUsers',
    beforeEnter: auth,
    component: () => import(/* webpackChunkName: "AssociationUsers" */ '../views/AssociationUsers.vue')
  },
  {
    path: "/pending-invites",
    name: "PendingInvites",
    beforeEnter: JoinGroupTeam,
    component: () =>
      import(/* webpackChunkName: "Teams" */ "../views/PendingInvites.vue"),
  },
  {
    path: "/association-invites",
    name: "AssociationInvites",
    beforeEnter: auth,
    component: () =>
      import(/* webpackChunkName: "Teams" */ "../views/PendingInvites.vue"),
  },
  {
    path: "/pending-invite/:c_id?",
    name: "PendingInvite",
    beforeEnter: auth,
    component: () =>
      import(/* webpackChunkName: "Teams" */ "../views/PendingInvites.vue"),
  },

  
  {
    path: "/founder-payment",
    name: "FounderUserPayment",
    beforeEnter: auth,
    component: () =>
      import(/* webpackChunkName: "Teams" */ "../views/founderUserPayment.vue"),
  },
  {
    path: "/search/:para?",
    name: "Search",
    beforeEnter: auth,
    component: () =>
      import(/* webpackChunkName: "Teams" */ "../views/Search.vue"),
  },
  {
    path: "/profile",
    name: "Profile",
    beforeEnter: auth,
    component: () =>
      import(/* webpackChunkName: "Teams" */ "../views/Profile.vue"),
  },
  {
    path: "/profile/:userlink?",
    name: "OtherProfile",
    beforeEnter: auth,
    component: () =>
      import(/* webpackChunkName: "Teams" */ "../views/otherProfile.vue"),
  },
  {
    path: "/friends",
    name: "FriendPage",
    beforeEnter: auth,
    component: () =>
      import(/* webpackChunkName: "Teams" */ "../views/FriendsPage.vue"),
  },
  {
    path: "/photos",
    name: "PhotosPage",
    beforeEnter: auth,
    component: () =>
      import(/* webpackChunkName: "Teams" */ "../views/PhotosPage.vue"),
  },
  {
    path: "/otherPhotos/:userlink?",
    name: "OtherPhotosPage",
    beforeEnter: auth,
    component: () =>
      import(/* webpackChunkName: "Teams" */ "../views/othersPhotos.vue"),
  },
  {
    path: "/otherFriends/:userlink?",
    name: "OtherFriendPage",
    beforeEnter: auth,
    component: () =>
      import(/* webpackChunkName: "Teams" */ "../views/otherFriends.vue"),
  },
  {
    path: "/live-stream",
    name: "LiveStream",
    beforeEnter: auth,
    component: () =>
      import(/* webpackChunkName: "Teams" */ "../views/LiveStream.vue"),
  },
  {
    path: "/login/:linkLogin?",
    name: "Login",
    beforeEnter: guest,
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Auth/Login.vue"),
  },
  {
    path: "/register/:link?",
    name: "Register",
    beforeEnter: guest,
    component: () =>
      import(/* webpackChunkName: "register" */ "../views/Auth/Register.vue"),
  },
  {
    path: "/verify",
    name: "Verify",
    beforeEnter: emailVerify,
    component: () =>
      import(/* webpackChunkName: "verify" */ "../views/Auth/Verify.vue"),
  },
  {
    path: "/verifyMobile",
    name: "VerifyMobile",
    beforeEnter: mobileVerify,
    component: () =>
      import(/* webpackChunkName: "verify" */ "../views/Auth/VerifyMobile.vue"),
  },
  {
    path: "/user-phone-verify",
    name: "RegisterMobile",
    beforeEnter: mobileVerify,
    component: () =>
      import(/* webpackChunkName: "verify" */ "../views/Auth/RegisterMobile.vue"),
  },
  {
    path: "/forgotPassword",
    name: "Forgot",
    component: () =>
      import(
        /* webpackChunkName: "forgotPassword" */ "../views/Auth/ForgotPassword/Forgot.vue"
      ),
  },
  {
    path: "/email",
    name: "Email",
    beforeEnter: passwordRoute,
    component: () =>
      import(
        /* webpackChunkName: "forgotPassword" */ "../views/Auth/ForgotPassword/Email.vue"
      ),
  },
  {
    path: "/verfy-code",
    name: "Code",
    beforeEnter: passwordRoute,
    component: () =>
      import(
        /* webpackChunkName: "forgotPassword" */ "../views/Auth/ForgotPassword/code.vue"
      ),
  },
  {
    path: "/newPassword",
    name: "NewPassword",
    beforeEnter: passwordRoute,
    component: () =>
      import(
        /* webpackChunkName: "forgotPassword" */ "../views/Auth/ForgotPassword/NewPassword.vue"
      ),
  },
  {
    path: "/notifications",
    name: "NotificationContent",
    beforeEnter: auth,
    component: () =>
      import(/* webpackChunkName: "Teams" */ "../views/NotificationPage.vue"),
  },
  {
    path: "/Thanks",
    name: "Thanks",
    beforeEnter: auth,
    component: () =>
      import(/* webpackChunkName: "Teams" */ "../views/thankyou.vue"),
  },
  {
    path: "/user-invites",
    name: "UserInvites",
    beforeEnter: auth,
    component: () =>
      import(/* webpackChunkName: "Teams" */ "../views/UserInvites.vue"),
  },
  {
    path: "/package-Invites",
    name: "AllInvites",
    beforeEnter: auth,
    component: () =>
      import(/* webpackChunkName: "Teams" */ "../views/allInvites.vue"),
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    beforeEnter: auth,
    component: () =>
      import(/* webpackChunkName: "Teams" */ "../views/PrivacyPolicy.vue"),
  },
  {
    path: "/Cookie-policy",
    name: "CookiePolicy",
    beforeEnter: auth,
    component: () =>
      import(/* webpackChunkName: "Teams" */ "../views/CookiePolicy.vue"),
  },
  {
    path: "/refund-and-return-policy",
    name: "RefundAndReturnPolicy",
    beforeEnter: auth,
    component: () =>
      import(
        /* webpackChunkName: "Teams" */ "../views/RefundAndReturnPolicy.vue"
      ),
  },
  {
    path: "/terms-and-conditions",
    name: "TermsAndConditions",
    beforeEnter: auth,
    component: () =>
      import(/* webpackChunkName: "Teams" */ "../views/TermsAndConditions.vue"),
  },

  {
    path: "/event-list",
    name: "EventCalander",
    beforeEnter: auth,
    component: () =>
      import(/* webpackChunkName: "Teams" */ "../views/EventCalendar.vue"),
  },

  {
    path: "/calendar",
    name: "Calendar",
    beforeEnter: auth,
    component: () =>
      import(
        /* webpackChunkName: "Teams" */ "../views/MyEventsCalendar.vue"
      ),
  },
  {
    path: "/event-calendar-table",
    name: "EventCalendarTable",
    beforeEnter: auth,
    component: () =>
      import(
        /* webpackChunkName: "Teams" */ "../views/EventCalendarTable.vue"
      ),
  },

  {
    path: "/go-pros/:key?/customer-session-link/:sessionSecret?",
    name: "GoPros",
    component: () =>
      import(/* webpackChunkName: "Teams" */ "../views/GoPros.vue"),
  },
  {
    path: "/go-pro/:key?/customer-session-link/:sessionSecret?",
    name: "GoPro",
    beforeEnter: auth,
    component: () =>
      import(/* webpackChunkName: "Teams" */ "../views/GoPro.vue"),
  },
  
  
  {
    path: "/confirm-payment/:session_id?",
    name: "ConfirmPayment",
    component: () =>
      import(/* webpackChunkName: "Teams" */ "../views/ConfirmPayment.vue"),
  },
  {
    path: "/our-terms-and-conditions",
    name: "OurTermsAndConditions",
    beforeEnter: guest,
    component: () =>
      import(
        /* webpackChunkName: "login" */ "../views/OurTermsAndConditions.vue"
      ),
  },

  {
    path: "/our-terms-and-condition",
    name: "OurTermsAndConditions",
    component: () =>
      import(
        /* webpackChunkName: "login" */ "../views/OurTermsAndConditions.vue"
      ),
  },

  {
    path: "/our-privacy-policy",
    name: "OurPrivacyPolicy",
    beforeEnter: guest,
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/OurPrivacyPolicy.vue"),
  },
  
  // {
  //   path: "/csae-policies",
  //   name: "CsaePolicy",
  //   component: () =>
  //     import(/* webpackChunkName: "login" */ "../views/CsaePolicy.vue"),
  // },
  {
   
    path: "/csae-policies",
    name: "CsaePolicy",
    beforeEnter: auth,
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Csaepolicies.vue"),
  },

  {
    path: "/csae-policy",
    name: "CsaePolicy",
    beforeEnter: guest,
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Csaepolicy.vue"),
  },
  {
    path: "/our-privacy-policies",
    name: "OurPrivacyPolicy",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/OurPrivacyPolicy.vue"),
  },

  {
    path: "/our-cookie-policy",
    name: "OurCookiePolicy",
    beforeEnter: guest,
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/OurCookiePolicy.vue"),
  },

  {
    path: "/settings",
    name: "Settings",
    beforeEnter: auth,
    component: () =>
      import(/* webpackChunkName: "Settings" */ "../views/Settings.vue"),
  },

  {
    path: "/score-game/:guid?",
    name: "SingleBoxScore",
    beforeEnter: auth,
    component: () =>
      import(/* webpackChunkName: "Teams" */ "../views/SingleBoxScore.vue"),
  },
  {
    path: "/my-event/detail/:id",
    name: "SingleEventDetail",
    beforeEnter: auth,
    component: () =>
      import(/* webpackChunkName: "Teams" */ "../views/SingleEventDetail.vue"),
  },
  {
    path: "/event/detail/:id",
    name: "AssociationSingleEventDetail",
    beforeEnter: auth,
    component: () =>
      import(/* webpackChunkName: "Teams" */ "../views/AssociationSingleEventDetail.vue"),
  },
  {
    path: "/following-event/detail/:id",
    name: "AssociationSingleEventDetailFollowing",
    beforeEnter: auth,
    component: () =>
      import(/* webpackChunkName: "Teams" */ "../views/AssociationSingleEventDetail.vue"),
  },
  {
    path: "/message-board/:messageId",
    name: "MessagesBoard",
    beforeEnter: auth, // Assuming you have a global authentication guard
    component: () =>
      import(/* webpackChunkName: "Teams" */ "../views/MessageBoard.vue"),
  },

  {
    path: "/tournament/:id",
    name: "Tournament",
    beforeEnter: auth,
    component: () =>
      import(/* webpackChunkName: "Teams" */ "../views/Tournament.vue"),
  },
  {
    path: "/event-game/:id",
    name: "EventGame",
    beforeEnter: auth,
    component: () =>
      import(/* webpackChunkName: "Teams" */ "../views/EventGame.vue"),
  },
  {
    path: "/tournament/game-score/:guid?",
    name: "TournamentScores",
    beforeEnter: auth,
    component: () =>
      import(/* webpackChunkName: "Teams" */ "../views/TournamentScores.vue"),
  },
  {
    path: "/contact-us",
    name: "ContactUs",
    beforeEnter: auth,
    component: () =>
      import(/* webpackChunkName: "Teams" */ "../views/ContactUs.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
