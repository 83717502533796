<template>
    <button v-if="showButton" @click="scrollToTop" class="scroll-btn">
      <svg width="20" height="20" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 2L4 10H10V22H14V10H20L12 2Z" fill="white"/>
    </svg>
    </button>
  </template>
  
  <script>
export default {
  data() {
    return {
      showButton: false,
      scrollContainer: null,
      observer: null,
    };
  },
  mounted() {
    this.findScrollContainer();
  
    // Observe DOM changes for dynamic .shop-side
    this.observer = new MutationObserver(() => {
      this.findScrollContainer();
    });
    this.observer.observe(document.body, { childList: true, subtree: true });

    // 🟢 Listen to route change & hide button
    this.$watch('$route', () => {
      this.showButton = false;
      this.cleanupScroll();
    });
    // Listen for window resize
    window.addEventListener("resize", this.handleResize);
  },
  beforeUnmount() {
    this.cleanupScroll();
    if (this.observer) this.observer.disconnect();
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    findScrollContainer() {
      // const container = document.querySelector(".shop-side") || document.querySelector(".task-table");
      let container = null;

      // First check for nested scrollable task-table inside shop-side
      const taskTable = document.querySelector(".shop-side .task-table");
      if (taskTable) {
        container = taskTable;
      } else {
        // fallback to .shop-side
        const fallback = document.querySelector(".shop-side");
        container = fallback;
      }

      
      if (!container) {
        this.cleanupScroll();
        return;
      }

      if (container !== this.scrollContainer) {
        this.cleanupScroll();
        this.scrollContainer = container;
        this.scrollContainer.addEventListener("scroll", this.handleScroll);
      }
    },
    handleScroll() {
      if (this.scrollContainer) {
        this.showButton = window.innerWidth > 768 && this.scrollContainer.scrollTop > 50;
      }
    },
    handleResize() {
      if (window.innerWidth <= 768) {
        this.showButton = false; // Auto hide on smaller screens
      }
    },
    scrollToTop() {
      if (this.scrollContainer) {
        this.scrollContainer.scrollTo({ top: 0, behavior: "smooth" });
      }
    },
    cleanupScroll() {
      if (this.scrollContainer) {
        this.scrollContainer.removeEventListener("scroll", this.handleScroll);
        this.scrollContainer = null;
      }
      this.showButton = false;
    },
  },
};
</script>

  
  <style>
  .scroll-btn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 36px; /* Set width */
  height: 36px; /* Set height */
  background: #188ef5;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 50%; /* Makes it circular */
  transition: opacity 0.3s ease-in-out, transform 0.2s;
  z-index: 1000;
  font-size: 20px; /* Adjust font size */
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Adds a soft shadow */
}
.scroll-btn:hover {
  background: #1c73c0;
}
  </style>  