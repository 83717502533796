<template>
    <div>
        <div :style="{ display: chatbotContainerDisplay }" id="chatbot-container">
            <!-- Chatbot interface -->
            <div id="chatbot-interface">
                <div id="chatbot-header">
                    <p>
                        <img class="header-image" src="../../assets/icon.png" />
                        <span>AI Help</span>
                    </p>
                    <img @click="closeAI" src="../../assets/Svg/close.svg" alt="close" class="close-ai" />
                </div>
                <div id="chatbot-chat">
                    <!-- Display chat messages in reverse order -->
                    <div v-for="(message, index) in chatMessages.slice().reverse()" :key="index" :class="{
                        'chatbot-messages': true,
                        'chatbot-sent-messages': message.type === 'sent',
                        'chatbot-received-messages': message.type === 'received',
                    }">
                        <p>{{ message.text }}</p>
                    </div>
                </div>

                <div id="chatbot-footer">
                    <div id="chatbot-input-container">
                        <input type="text" v-model="inputText" id="chatbot-input" name="chatbot-input"
                            placeholder="Type a command here..." @keydown.enter.prevent="sendOnEnter" ref="chatInput" />
                    </div>
                    <div id="chatbot-new-message-send-button" @click="sendInputTo">
                        <div>
                            <div class="shop-products-img-loader" v-if="showLoader">
                                <div class="lds-dual-ring-media"></div>
                            </div>
                            <img class="closeIcon" src="../../assets/images/send.png" v-else>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="chatbot-open-container" v-show="showChatbotContainer">
            <div id="open-chat-button" @click="toggleChat"><img class="chatIcon" src="../../assets/Svg/AI Help.svg">
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            chatbotContainerDisplay: "none",
            isOpen: false,
            inputText: "",
            chatMessages: [], // To store chat messages
            showLoader: 0,
            currentTabUrl : "",
            showChatbotContainer: true, // Control the display of the chatbot container
        };
    },
    methods: {
        sendInputTo() {
            const newText = this.inputText.trim();

            if (newText !== "") {
                this.inputText = "";
                this.showLoader = 1;
                // Create an object to send to the server
                const messageData = {
                    message: newText,
                };

                // Make an HTTP POST request to your Node.js server
                axios
                    .post(process.env.VUE_APP_API_URL + "chatbot/sendMessage", messageData)
                    .then((response) => {
                        if (response.data.chatbotResponse.status === 200) {
                            const responseData = response.data;
                            this.showLoader = 0;

                            // Add user's input to chatMessages
                            this.addMessage("sent", newText);
                            // this.addMessage("received", responseData.chatbotResponse.chatbotResponse);
                            const responseLines = responseData.chatbotResponse.chatbotResponse.split("\n");

                            // Iterate through response lines and add them to chatMessages
                            responseLines.forEach((line) => {
                                this.addMessage("received", line);
                            });
                        }
                    })
                    .catch((error) => {
                        if (error.response) {
                            // Prepare the unauthorized message split into lines
                            const unauthorizedMessage = "Unauthorized \n Please log in to continue.";
                            const unauthorizedLines = unauthorizedMessage.split("\n");

                            // Handle errors with HTTP response status codes
                            if (error.response.status === 401) {
                                this.addMessage("sent", newText);
                                unauthorizedLines.forEach((line) => {
                                    this.addMessage("received", line);
                                });
                                this.showLoader = 0;
                            } else if (error.response.status === 404) {
                                this.addMessage("sent", newText);
                                this.addMessage("received", error.response.data.error);
                                this.showLoader = 0;
                            } else if (error.response.status === 500) {
                                this.addMessage("sent", newText);
                                this.addMessage("received", error.response.data.error);
                                this.showLoader = 0;
                            } else {
                                this.addMessage("sent", newText);
                                this.addMessage("received", error.response.statusText);
                                this.showLoader = 0;
                            }
                        } else {
                            this.addMessage("sent", newText);
                            this.addMessage("received", "Network error or server unreachable.");
                            this.showLoader = 0;
                        }
                    });
            }
        },
        sendOnEnter() {
            if (this.inputText.trim() !== "") {
                this.sendInputTo();
                this.$refs.chatInput.focus(); // Focus the input field again after sending
            }
        },
        toggleChat() {
            this.isOpen = true;
            this.chatbotContainerDisplay = "block";
        },
        closeAI() {
            this.isOpen = false;
            this.chatbotContainerDisplay = "none"
        },
        addMessage(type, text) {
            this.chatMessages.push({ type, text });
        },
    },
    mounted() {
        this.currentTabUrl = window.location.pathname;
    if (this.currentTabUrl.startsWith("/go-pros")) {
        this.isOpen = false;
        this.chatbotContainerDisplay = "none";
        this.showChatbotContainer = false;
    }
        this.$root.$on("showChatbot", (data) => {
            if (data === false) {
                this.isOpen = false;
                this.chatbotContainerDisplay = "none";
                this.showChatbotContainer = false;
            } else if (data === true) {
                this.showChatbotContainer = true;
            }
        });
    },
};
</script>

<style scoped>
.shop-products-img-loader {
    position: sticky;
    left: 0 !important;
    z-index: 99;
}

#chatbot-container {
    z-index: 1000;
    color: #2c2325;
    position: absolute;
    bottom: -30px;
    right: 0px;
    width: 400px;
    height: 40vh;
    animation: slide-up 1s ease-in-out forwards;
    /* Apply the animation */
}

@keyframes slide-up {
    0% {
        bottom: -50vh;
        /* Starting position */
    }

    100% {
        bottom: 0;
        /* Ending position */
    }
}

#chatbot-interface {
    height: 40vh;
    width: 100%;
    background-color: #e2f5fe;
    border-radius: 5px;
    box-shadow: 0px 2px 4px 0px #00000040;
    -webkit-box-shadow: 0px 2px 4px 0px #00000040;
    -moz-box-shadow: 0px 2px 4px 0px #00000040;
    -ms-box-shadow: 0px 2px 4px 0px #00000040;
    -o-box-shadow: 0px 2px 4px 0px #00000040;
}

#chatbot-header {
    color: white;
    font-size: 18px;
    font-weight: 600;
    border-top-left-radius: 0.7rem;
    border-top-right-radius: 0.7rem;
    background: #188ef5;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    box-shadow: 0 0 5px 2px #a9a9a97d;
}

.header-image {
    width: 35px;
    filter: brightness(15);
}

#chatbot-header p span {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    color: #FFFFFF;
}

#chatbot-header>img {
    cursor: pointer;
    filter: brightness(15);
    margin: auto 0;
    width: 35px;
    height: 35px;
}

#chatbot-chat {
    height: calc(100% - 22px - 6rem);
    border-bottom-left-radius: 0.7rem;
    border-bottom-right-radius: 0.7rem;
    padding: 1rem;
    overflow-y: scroll;
    display: flex;
    flex-direction: column-reverse;
}

.chatbot-messages {
    padding: 1rem;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    border-radius: 1rem;
    margin-top: 0.3rem;
    margin-bottom: 0.3rem;
    width: fit-content;
}

.chatbot-received-messages {
    border-top-left-radius: 0rem;
    background: white;
    color: black
}

.chatbot-sent-messages {
    background: #407FFF;
    color: white;
    border-top-right-radius: 0rem;
    margin-left: auto;
    margin-right: 0;
}

#chatbot-footer {
    padding-top: 1rem;
    padding-bottom: 1rem;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: center;
}

#chatbot-input-container {
    width: 85%;
}

#chatbot-input {
    width: 100%;
    padding: 5px;
    color: black;
    border: 0.1rem solid #188ef5 !important;
    border-radius: 0.5rem;
    margin-left: 5px;
}

#chatbot-input:focus {
    outline-offset: 0px !important;
    outline: none !important;
    border: 0.1rem solid #8a6e2f;
}

#chatbot-new-message-send-button {
    cursor: pointer;
    margin-right: 5px;
    padding-bottom: 1px;
}

#send-icon {
    color: white;
}

#chatbot-open-container {
    height: 48px;
    width: 48px;
    text-align: center;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    position: fixed;
    z-index: 1000;
    right: 70px;
    top: 10px;
}

@media screen and (max-width: 576px) {
    #chatbot-open-container {
        height: 30px;
        width: 30px;
        text-align: center;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
        position: fixed;
        z-index: 1000;
        right: 50px;
        top: 20px;
    }
}

#chatbot-open-container img {
    width: 100%;
}

@media screen and (min-width: 992px) {

    #chatbot-open-container,
    #chatbot-interface {
        display: none !important;
    }
}
</style>