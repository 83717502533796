import "@babel/polyfill";
import "mutationobserver-shim";
import Vue from "vue";
import "./plugins/bootstrap-vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import "vue2-timepicker/dist/VueTimepicker.css";
import VueObserveVisibility from "vue-observe-visibility";
import VueContentPlaceholders from "vue-content-placeholders";
import Vuelidate from "vuelidate";
// import moment from "moment";
import FlashMessage from "@smartweb/vue-flash-message";
import VueSimpleAlert from "vue-simple-alert";
import ToggleButton from "vue-js-toggle-button";
import Select2 from "v-select2-component";
import * as VueGoogleMaps from "vue2-google-maps";
import * as moment from "moment-timezone";
import vueCountryRegionSelect from "vue-country-region-select";
import VueLoading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import VueRx from "vue-rx";
import VuejsClipper from "vuejs-clipper/dist/vuejs-clipper.umd.min";
import "vuejs-clipper/dist/vuejs-clipper.css";
import VueCountdown from "@chenfengyuan/vue-countdown";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

Vue.use(Toast, {
  transition: "Vue-Toastification__bounce",
  maxToasts: 20,
  newestOnTop: true,
});

Vue.component(VueCountdown.name, VueCountdown);

// install vue-rx
Vue.use(VueRx);
Vue.use(VuejsClipper);

Vue.use(VuejsClipper, {
  components: {
    clipperBasic: true,
    clipperPreview: true,
  },
});

Vue.use(VuejsClipper, {
  components: {
    clipperBasic: "image-clipper-basic",
    clipperPreview: "my-preview",
  },
});
Vue.directive('phone-mask', {
  bind(el) {
    el.addEventListener('input', (event) => {
      let value = event.target.value.replace(/\D/g, ''); // Remove all non-digit characters
      if (value.length > 10) value = value.slice(0, 10); // Limit to 10 digits

      // Format as (XXX) XXX-XXXX
      if (value.length >= 6) {
        event.target.value = `(${value.slice(0, 3)}) ${value.slice(3, 6)}-${value.slice(6)}`;
      } else if (value.length >= 3) {
        event.target.value = `(${value.slice(0, 3)}) ${value.slice(3)}`;
      } else {
        event.target.value = value;
      }

      // Emit input event for v-model binding
      const inputEvent = new Event('input', { bubbles: true });
      event.target.dispatchEvent(inputEvent);
    });
  },
});

Vue.use(VuejsClipper, {
  components: null,
  parentPropName: "myCustomerName",
  /*
   parentPropName:
    Vuejs-clipper Adds property to Vue instance in order to store `clipper-preview` list.
    You can change the property name
    default: '_imgPreviewLists'
  */
});

Vue.component("v-select", vSelect);

Vue.component("Select2", Select2);
Vue.use(vueCountryRegionSelect);

Vue.use(VueLoading);
Vue.use(DatePicker);
Vue.use(ToggleButton);
Vue.use(VueSimpleAlert);
Vue.use(FlashMessage);
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_API_URL_GOOGLE_MAP,
    libraries: "places",
  },
});

Vue.use(VueContentPlaceholders);
Vue.use(Vuelidate);
Vue.component("google-map", VueGoogleMaps.Map);
Vue.component("google-maps-marker", VueGoogleMaps.Marker);
Vue.component("gmap-autocomplete", VueGoogleMaps.VueGoogleAutocomplete);
axios.defaults.baseURL = process.env.VUE_APP_API_URL;
Vue.prototype.$postStorageURL = process.env.VUE_APP_API_URL_STORAGE_POST;
Vue.prototype.$bracketStorageURL = process.env.VUE_APP_API_URL_STORAGE_BRACKETS;
Vue.prototype.$userProfileStorageURL = process.env.VUE_APP_API_URL_STORAGE;
Vue.prototype.$groupAvatarStorageURL =
  process.env.VUE_APP_API_URL_STORAGE_GROUP;
Vue.prototype.$eventAvatarStorageURL =
  process.env.VUE_APP_API_URL_STORAGE_EVENT;
Vue.prototype.$eventSponserStorageURL =
  process.env.VUE_APP_API_URL_EVENT_SPONSER;
Vue.prototype.$eventHotelStorageURL =
  process.env.VUE_APP_API_URL_EVENT_HOTEL;
Vue.prototype.$chatImageStorageURL = process.env.VUE_APP_API_URL_STORAGE_CHAT;
Vue.prototype.$productImageStorageURL =
  process.env.VUE_APP_API_URL_STORAGE_PRODUCT;
Vue.prototype.$galleryImageStorageURL =
  process.env.VUE_APP_API_URL_STORAGE_GALLERY;
Vue.prototype.$adBannerStorageURL = process.env.VUE_APP_API_URL_STORAGE_BANNER;
Vue.prototype.$googleMapApi = process.env.VUE_APP_API_URL_GOOGLE_MAP;
Vue.prototype.$platform = process.env.VUE_APP_PLATFORM;
Vue.prototype.$staxPayKey = process.env.VUE_APP_API_URL_STAXPAY_APP_KEY;

Vue.prototype.$stripePublishableKey = process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY;
Vue.prototype.$stripePricingTableId = process.env.VUE_APP_STRIPE_PRICING_TABLE_ID;

Vue.prototype.$stripeSecretKey = process.env.VUE_APP_STRIPE_SECRET_KEY;

// // Firebase CREDENTIALS
// Vue.prototype.$apiKey = process.env.API_KEY;
// Vue.prototype.$authDomain = process.env.AUTH_DOMAIN;
// Vue.prototype.$databaseURL = process.env.DATABASE_URL;
// Vue.prototype.$projectId = process.env.PROJECT_ID;
// Vue.prototype.$storageBucket = process.env.STORAGE_BUCKET;
// Vue.prototype.$messagingSenderId = process.env.MESSAGING_SENDER_ID;
// Vue.prototype.$appId = process.env.APP_ID;
// Vue.prototype.$measurementId = process.env.MEASUREMENT_ID;
// Vue.prototype.$tokenKey = process.env.TOKEN_KEY;

const d = new Date();
let time = d.getTime();
Vue.prototype.$time = time;

Vue.filter("reverse", function(value) {
  return value.slice().reverse();
});
Vue.filter("formatDate", function(value) {
  if (value) {
    return moment(String(value), "").format("MMM DD • h:mm A");
  }
});
Vue.filter("formatDateComment", function(value) {
  if (value) {
    return moment(String(value), "").format("MMM DD, YY- h:mm A");
  }
});

Vue.filter("formatDateOfBirth", function(value) {
  if (value) {
    return moment(String(value), "").format("MMM DD, YYYY");
  }
});
Vue.filter("formatDateWeather", function(value) {
  if (value) {
    return moment(String(value), "").format("MMM DD");
  }
});

Vue.filter("formatTimeToAM", function(value) {
  if (value) {
    return moment(value, "HH:mm").format("h:mm A");
  }
});
Vue.filter("formatTimeAgo", function(value) {
  if (value) {
    console.log(Intl.DateTimeFormat().resolvedOptions().timeZone);

    // const dateFinal= moment(value,"YYYY-MM-DD[T]HH:mm:ss").subtract( 'hours', -5);
    // var notificationDate = moment(dateFinal, 'YYYY-MM-DD[T]HH:mm:ss').format("YYYY-MM-DD[T]HH:mm:ss");

    const dateFinal = moment.tz(
      value,
      Intl.DateTimeFormat().resolvedOptions().timeZone
    );
    var notificationDate = moment(dateFinal, "YYYY-MM-DD[T]HH:mm:ss").format(
      "YYYY-MM-DD[T]HH:mm:ss"
    );
    return moment(notificationDate).fromNow();
  }
});

Vue.filter("formatDateChat", function(value) {
  if (value) {
    const array1 = value.split("/");
    const month = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "July",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return month[array1[1] - 1] + " " + array1[0] + ", " + array1[2];
  }
});

Vue.use(VueObserveVisibility);

Vue.config.productionTip = false;

axios.interceptors.request.use(function(config) {
  config.headers.common = {
    Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    "Content-Type": "application/json",
    Accept: "application/json",
  };
  return config;
});

const createCustomAxiosInstance = () => {
  const instance = axios.create();

  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      console.log(error);
      if (!error.response) {
        // Network error occurred, such as no internet connection
        Vue.$toast.clear();
        Vue.$toast.error(
          "No internet. Please check your internet connection & try again.",
          {
            position: "bottom-right",
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          }
        );
      } else if (error.response.status === 503) {
        // Handle other HTTP status codes as needed
        console.log("Service Unavailable");
      }

      return Promise.reject(error);
    }
  );

  window.addEventListener("online", () => {
    Vue.$toast.clear();
    Vue.$toast.success("Back online.", {
      position: "bottom-right",
      timeout: 5000,
      closeOnClick: true,
      pauseOnFocusLoss: true,
      pauseOnHover: true,
      draggable: true,
      draggablePercent: 0.6,
      showCloseButtonOnHover: false,
      hideProgressBar: true,
      closeButton: "button",
      icon: true,
      rtl: false,
    });
    // const currentId = localStorage.getItem("currentUserId");
    // firestoreServe.updateUserOnlineStatus(currentId);
  });

  window.addEventListener("offline", () => {
    Vue.$toast.clear();
    Vue.$toast.error(
      "No internet. Please check your internet connection & try again.",
      {
        position: "bottom-right",
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      }
    );
    
  });
  // const currentId = localStorage.getItem("currentUserId");
  // firestoreServe.updateUserOnlineStatus(currentId);

  return instance; 
};

const customAxios = createCustomAxiosInstance();
// for local
// customAxios.get("localhost:8080/");
//staging
customAxios.get(process.env.VUE_APP_URL)

Vue.directive("click-outside", {
  bind(el, binding, vnode) {
    el.clickOutsideEvent = (event) => {
      if (!(el === event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener("click", el.clickOutsideEvent);
  },
  unbind(el) {
    document.body.removeEventListener("click", el.clickOutsideEvent);
  },
});
Vue.directive("focus", {
  inserted: function(el) {
    el.focus();
  },
  update: function(el) {
    Vue.nextTick(function() {
      el.focus();
    });
  },
});

Vue.directive("focus", {
  bind: function() {
    var object = this.el;
    Vue.nextTick(function() {
      object.focus();
    });
  },
});

var vm = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
global.vm = vm;
